import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

import "./AboutSection.scss";

function AboutSection() {
    const { t } = useTranslation()

    return (
        <section className="aboutsection py">
            <Container>

                <h2 className="mt-1 mb-4">
                    {t("about-utube")}
                </h2>
                <iframe
                    width="100%"
                    height="550"
                    src={'https://www.youtube.com/embed/oW1uVqzbmcU'}
                    title="Orange Textile"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                />
                <h2>{t("services")}</h2>
                <p className="aboutsection__subtitle">
                    {t("services-text")}
                </p>
                <Row className="overflow-hidden">
                    <Col md={4} data-aos="fade-right" className="p-4">
                        <div className="aboutsection__shadow">
                            <div className="aboutsection__img img1" />
                            <div className="aboutsection__box">
                                <h4> {t("services-dyeing")}</h4>
                                <p>
                                    {t("services-dyeing-text")}
                                </p>
                                <HashLink to="/about#dyeing" className="button">{t("about")}</HashLink>
                            </div>
                        </div>
                    </Col>

                    <Col md={4} data-aos="fade-up" className="p-4">
                        <div className="aboutsection__shadow">
                            <div className="aboutsection__img img2" />
                            <div className="aboutsection__box">
                                <h4> {t("services-printing")}</h4>
                                <p>
                                    {t("services-printing-text")}
                                </p>
                                <HashLink to="/about#packing" className="button">{t("about")}</HashLink>
                            </div>
                        </div>
                    </Col>

                    <Col md={4} data-aos="fade-left" className="p-4">
                        <div className="aboutsection__shadow">
                            <div className="aboutsection__img img3" />
                            <div className="aboutsection__box">
                                <h4> {t("services-sewing")}</h4>
                                <p>
                                    {t("services-sewing-text")}
                                </p>
                                <HashLink to="#" className="button">{t("about")}</HashLink>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutSection;