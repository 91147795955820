import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Footer.scss'

function Footer() {
    const { t } = useTranslation()

    return (
        <footer className="footer">
            <div className='footer__overlay'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className='d-flex align-items-center'>
                                <h3 className='fw-bold fs-4'>ORANGE TEXTILE</h3>
                            </div>
                            <p>
                                {t("logo-bottom")}
                            </p>
                        </Col>
                        <Col md={4}>
                            <h3>  {t("company")}</h3>

                            <div className='footer__link'>
                                <Link to="/about">
                                    {t("О нас")}
                                </Link>
                                <Link to="/products">
                                    {t("Продукция")}
                                </Link>
                                <Link to="/contacts">
                                    {t("Контакты")}
                                </Link>
                                <Link to="/">
                                    {t("Новости")}
                                </Link>
                            </div>
                        </Col>
                        <Col md={4}>
                            <h3>
                                {t("contact-us")}
                            </h3>

                            <div className='footer__link'>
                                <a href="tel:+998993195533">+99899-319-55-33</a>
                                <a href="tel:+998993185533">+99899-318-55-33</a>
                            </div>

                            <h3 className='mt-5'>
                                {t("address")}
                            </h3>

                            <div className='footer__link'>
                                <p>
                                    {t("address-name")}
                                </p>
                            </div>
                        </Col>

                        <Col className='footer__bottom'>
                            <span>
                                ORANGE TEXTILE 2022
                            </span>

                            <a href="https://wtma.uz/">
                                Website developer wtma.uz
                            </a>

                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}

export default Footer