import { useTranslation } from 'react-i18next';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MyForm from '../Form/MyForm';

import { Col, Container } from 'react-bootstrap'

import './ContactsPage.scss';

function ContactsPage() {
    const { t } = useTranslation()

    return (
        <section className='contacts'>
            <div className='contacts__header'>
                <div className='contacts__overlay'>
                    <Container className='text-center'>
                        <h1>
                            {t("КОНТАКТЫ")}
                        </h1>
                        <span>ORANGE TEXTILE</span>
                    </Container>
                </div>
            </div>

            <Container className='mb-5'>
                <h6> {t("мы в контакте")}</h6>
                <div className='contacts__title'>
                    <PhoneIphoneIcon />
                    <h2>
                        +99899-319-55-33
                    </h2>
                </div>

                <div className='contacts__adress mb-2'>
                    <h5>+99899-318-55-33</h5>

                    <h5>
                        {t("address-short")}
                    </h5>
                </div>
                <Col md={6} className="m-auto">
                    <MyForm />
                </Col>
            </Container>
            <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2994.9244421375565!2d69.26198099999999!3d41.353995999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zNDHCsDIxJzE0LjQiTiA2OcKwMTUnNDMuMSJF!5e0!3m2!1sru!2s!4v1660030081921!5m2!1sru!2s" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </section>
    )
}

export default ContactsPage