import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { Autoplay, Pagination, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Fancybox from '../ImageViewer/Fancybox'

import first from '../../resources/img/ProductsPage/IMG_7838.webp'
import second from '../../resources/img/ProductsPage/IMG_7859.webp'
import third from '../../resources/img/ProductsPage/IMG_7882.webp'
import fourth from '../../resources/img/ProductsPage/IMG_7909.webp'
import fifth from '../../resources/img/ProductsPage/IMG_7925.webp'
import sixth from '../../resources/img/ProductsPage/IMG_7951.webp'

import "swiper/css";
import "swiper/css/navigation";
import "./ProductSection.scss";

function ProductSection() {
    const { t } = useTranslation()

    return (
        <section className='productsection'>
            <Container>
                <h6>{t("gallery")}</h6>

                <h2>Orange Textile</h2>
                <Swiper
                    slidesPerView={3}
                    freeMode={true}
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, FreeMode, Pagination]}
                    className="mySwiper pb-4"
                >
                    <Fancybox options={{ infinite: false }}>
                        <Row className='overflow-hidden'>
                            <SwiperSlide className='d-flex justify-content-center'>
                                <Col md={4} data-aos="zoom-in">
                                    <a
                                        data-fancybox="gallery"
                                        data-src={first}
                                    >
                                        <img src={first} width="416" height="277" alt="products" />
                                    </a>
                                </Col>
                            </SwiperSlide>
                            <SwiperSlide className='d-flex justify-content-center'>
                                <Col md={4} data-aos="zoom-in">
                                    <a
                                        data-fancybox="gallery"
                                        data-src={second}
                                    >
                                        <img src={second} width="416" height="277" alt="products" />
                                    </a>
                                </Col>
                            </SwiperSlide>
                            <SwiperSlide className='d-flex justify-content-center'>
                                <Col md={4} data-aos="zoom-in">
                                    <a
                                        data-fancybox="gallery"
                                        data-src={third}
                                    >
                                        <img src={third} width="416" height="277" alt="products" />
                                    </a>
                                </Col>
                            </SwiperSlide>
                            <SwiperSlide className='d-flex justify-content-center'>
                                <Col md={4} data-aos="zoom-in">
                                    <a
                                        data-fancybox="gallery"
                                        data-src={fourth}
                                    >
                                        <img src={fourth} width="416" height="277" alt="products" />
                                    </a>
                                </Col>
                            </SwiperSlide>
                            <SwiperSlide className='d-flex justify-content-center'>
                                <Col md={4} data-aos="zoom-in">
                                    <a
                                        data-fancybox="gallery"
                                        data-src={fifth}
                                    >
                                        <img src={fifth} width="416" height="277" alt="products" />
                                    </a>
                                </Col>
                            </SwiperSlide>
                            <SwiperSlide className='d-flex justify-content-center'>
                                <Col md={4} data-aos="zoom-in">
                                    <a
                                        data-fancybox="gallery"
                                        data-src={sixth}
                                    >
                                        <img src={sixth} width="416" height="277" alt="products" />
                                    </a>
                                </Col>
                            </SwiperSlide>
                        </Row>
                    </Fancybox>
                </Swiper>
            </Container>

            <div className='callback'>
                <div>
                    <h6>{t("cooperate")}</h6>

                    <h2>{t("touch")}</h2>

                    <HashLink to="/contacts/#map" className='button-black'>{t("location")}</HashLink>
                </div>
            </div>
        </section >
    )
}

export default ProductSection