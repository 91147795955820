import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap";

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PaymentIcon from '@mui/icons-material/Payment';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import GroupsIcon from '@mui/icons-material/Groups';

import './AdvantageSection.scss'

function AdvantageSection() {
    const { t } = useTranslation()

    return (
        <section className='advantage py'>
            <Container>
                <Row className='advantage__mission' >
                    <h2>
                        {t("advantages")}
                    </h2>
                    <Col data-aos="flip-down" md={5}>
                        <div className='advantage__mission-box'>
                            <SupportAgentIcon />

                            <h3>
                                {t("advan1")}
                            </h3>

                            <p>
                                {t("adven1-text")}
                            </p>
                        </div>
                    </Col>

                    <Col data-aos="flip-down" md={5}>
                        <div className='advantage__mission-box'>
                            <PaymentIcon />

                            <h3>
                                {t("advan2")}
                            </h3>

                            <p>
                                {t("adven2-text")}
                            </p>
                        </div>
                    </Col>

                    <Col data-aos="flip-down" md={5}>
                        <div className='advantage__mission-box'>
                            <ThumbUpOffAltIcon />

                            <h3>
                                {t("advan3")}
                            </h3>

                            <p>
                                {t("adven3-text")}
                            </p>
                        </div>
                    </Col>

                    <Col data-aos="flip-down" md={5}>
                        <div className='advantage__mission-box'>
                            <GroupsIcon />

                            <h3>
                                {t("advan4")}
                            </h3>

                            <p>
                                {t("adven4-text")}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AdvantageSection;