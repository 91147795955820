import AboutSection from "../Sections/AboutSection";
import AdvantageSection from "../Sections/AdvantageSection";
import IntroSection from "../Sections/IntoSection";
import ProductSection from "../Sections/ProductSection";
import ContactsSection from "../Sections/ContactsSection";
import SvgMap from "../SvgMap/SvgMap";


export default function MainPage() {

    return (
        <>
            <IntroSection />
            <AboutSection />
            <SvgMap />
            <AdvantageSection />
            <ProductSection />
            <ContactsSection />
        </>
    )
}