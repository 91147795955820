import { useTranslation } from 'react-i18next';
import { Col, Container } from 'react-bootstrap';

import MyForm from '../Form/MyForm';

import "./ContactsSection.scss";

function ContactsSection() {
    const { t } = useTranslation()

    return (
        <section className='contactssection'>
            <div className='contactssection-overlay'>
                <Container className='my-5'>
                    <Col md={6} className="m-auto">
                        <h2 className='mb-3 text-center'>{t("aboutpage")}</h2>
                        <MyForm />
                    </Col>
                </Container>
            </div>
        </section>
    )
}

export default ContactsSection