import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import img1 from '../../resources/img/news/news1.jpg'
import './News.scss'

function News() {
    const { t } = useTranslation()

    return (
        <section className="news">
            <div className='news__header'>
                <div className='news__overlay' >
                    <Container className='text-center'>
                        <h1>
                            {t("НОВОСТИ")}
                        </h1>
                        <span>ORANGE TEXTILE</span>
                    </Container>
                </div>
            </div>

            <div className="news__section">
                <Container>
                    <h2>
                        {t("news-title")}
                    </h2>
                    <Row >
                        <Col md={5}>
                            <p>
                                {t("news-text")}
                            </p>

                            <p>
                            {t("news-text2")}
                            </p>
                        </Col>
                        <Col md={7}>
                            <img className='news__section-img' src={img1} alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default News