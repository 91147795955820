import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import "./IntroSection.scss";
import "aos/dist/aos.css";

function IntroSection() {
    const { t } = useTranslation()

    useEffect(() => {
        AOS.init({
            duration: 1200
        });
    }, []);

    return (
        <section className="intro">
            <div className="intro__header">
                <div className="intro__overlay">
                    <Container data-aos="zoom-in">
                        <h1>
                            ORANGE TEXTILE
                        </h1>

                        <p>
                            {t('company-text')}
                            <br />
                            {t('company-text2')}
                        </p>

                        <Link to="/about" className="button">{t("about-us")}</Link>
                    </Container>
                </div>
            </div>
        </section>
    )
}

export default IntroSection;