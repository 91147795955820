import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Fancybox from '../ImageViewer/Fancybox'

import pic1 from '../../resources/img/ProductsPage/Products/about7995.webp'
import pic2 from '../../resources/img/ProductsPage/Products/IMG_7947.webp'
import pic3 from '../../resources/img/ProductsPage/Products/IMG_7949.webp'
import pic4 from '../../resources/img/ProductsPage/Products/IMG_7951.webp'
import pic5 from '../../resources/img/ProductsPage/Products/IMG_7952.webp'
import pic6 from '../../resources/img/ProductsPage/Products/IMG_7955.webp'
import pic7 from '../../resources/img/ProductsPage/Products/IMG_7957.webp'
import pic8 from '../../resources/img/ProductsPage/Products/IMG_7959.webp'
import pic9 from '../../resources/img/ProductsPage/Products/IMG_7961.webp'
import pic10 from '../../resources/img/ProductsPage/Products/IMG_7966.webp'
import pic11 from '../../resources/img/ProductsPage/Products/IMG_7970.webp'
import pic12 from '../../resources/img/ProductsPage/Products/IMG_7971.webp'
import pic13 from '../../resources/img/ProductsPage/Products/IMG_7974.webp'
import pic14 from '../../resources/img/ProductsPage/Products/IMG_7991.webp'
import pic15 from '../../resources/img/ProductsPage/Products/IMG_7998.webp'
import pic16 from '../../resources/img/ProductsPage/Products/IMG_8002.webp'

import './ProductsPage.scss'

export default function ProductsPage() {
    const [t] = useTranslation()

    return (
        <section className='products'>
            <div className='products__header'>
                <div className='products__overlay' >
                    <Container className='text-center'>
                        <h1>
                        {t("ПРОДУКЦИЯ")}
                        </h1>

                        <p>{t("polotno")}</p>

                        <Link className='button' to="/contacts">{t("Заказать")}</Link>
                    </Container>
                </div>
            </div>

            <Container>
                <h3>
                    {t("products-title")}
                </h3>

                <ul>
                    <li>
                        {t("Супрем")}
                    </li>
                    <li>
                    {t("Интерлок")}
                    </li>
                    <li>
                    {t("Кашкорсе")}
                    </li>
                    <li>
                    {t("Рибана")}
                    </li>
                    <li>
                    {t("Вилюр")}
                    </li>
                    <li>
                    {t("Футер")}
                    </li>
                    <li>
                    {t("Меланж")}
                    </li>
                </ul>

                <Fancybox options={{ infinite: false }}>
                    <Row>
                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic1}
                            >
                                <img src={pic1} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic2}
                            >
                                <img src={pic2} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic3}
                            >
                                <img src={pic3} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic4}
                            >
                                <img src={pic4} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic5}
                            >
                                <img src={pic5} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic6}
                            >
                                <img src={pic6} width="100%" alt="?" />
                            </a>
                        </Col>
                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic7}
                            >
                                <img src={pic7} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic8}
                            >
                                <img src={pic8} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic9}
                            >
                                <img src={pic9} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic10}
                            >
                                <img src={pic10} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic11}
                            >
                                <img src={pic11} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic12}
                            >
                                <img src={pic12} width="100%" alt="?" />
                            </a>
                        </Col>
                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic13}
                            >
                                <img src={pic13} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic14}
                            >
                                <img src={pic14} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic15}
                            >
                                <img src={pic15} width="100%" alt="?" />
                            </a>
                        </Col>

                        <Col md={3}>
                            <a
                                data-fancybox="gallery"
                                data-src={pic16}
                            >
                                <img src={pic16} width="100%" alt="?" />
                            </a>
                        </Col>
                    </Row>
                </Fancybox>
            </Container>
        </section>
    )
}
