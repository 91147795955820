import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from 'react-bootstrap';

import { Autoplay, Pagination, FreeMode } from "swiper";

import concept from '../../resources/img/Partners/Italian.png'
import acoola from '../../resources/img/Partners/MSK.png'
import artie from '../../resources/img/Partners/ДочкиСыночки.png'
import DM from '../../resources/img/Partners/Котмаркот.png'
import melon from '../../resources/img/Partners/Купалинка.png'
import sela from '../../resources/img/Partners/Текстиль.png'
import bonito from '../../resources/img/Partners/bonito.png'
import daka from '../../resources/img/Partners/DAKA.png'
import saas from '../../resources/img/Partners/saas.png'
import takro from '../../resources/img/Partners/takro.png'
import socks from  '../../resources/img/Partners/logos1.png'
import mirmar from  '../../resources/img/Partners/logo2.webp'

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


import "./PartnersSection.scss";

function PartnersSection() {
    const { t } = useTranslation()

    return (
        <section className='partnerssection py'>
            <Container>
                <h2 className='mb-4 fs-1'>{t("partners")}</h2>
                <Swiper
                    slidesPerView={3}
                    freeMode={true}
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, FreeMode, Pagination]}
                    className="mySwiper__partners"
                >
                    <Row>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={acoola} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={concept} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={artie} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={DM} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={melon} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={sela} alt="?" />
                            </Col>
                        </SwiperSlide>

                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={bonito} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={daka} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={saas} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={takro} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={socks} alt="?" />
                            </Col>
                        </SwiperSlide>
                        <SwiperSlide className='d-flex justify-content-center'>
                            <Col xs={6} md={3} className="partnerssection__box">
                                <img src={mirmar} alt="?" />
                            </Col>
                        </SwiperSlide>
                    </Row>
                </Swiper>
            </Container>
        </section>
    )
}

export default PartnersSection