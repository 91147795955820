import { Col, Row } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { Form, } from "react-bootstrap";

import InputMask from "react-input-mask";

import './MyForm.scss'
import ApplicationService from '../../services/ApplicationService';
import { useState } from 'react';


function MyForm() {
    const [pend, setPend] = useState(false)
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")

    const application = new ApplicationService()
    const onPhoneChange = (event) => {
        setPhone(event.target.value)
    }
    const onEmailChange = (event) => {
        setEmail(event.target.value)
    }
    const onNameChange = (event) => {
        setName(event.target.value)
    }
    const onMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const handleClose = () => {
        setPhone("")
        setEmail("")
        setName("")
        setMessage("")
    }

    const submit = event => {
        event.preventDefault()
        setPend(true)

        application.createApplication(phone, email, name, message).then(() => {
            handleClose()
            setPend(false)
        })

    }

    const [t] = useTranslation()

    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#0db8ca"
            },
            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
                borderColor: "#0db8ca",
            },
        },
    }

    const btnn = css`
    border-color: #0db8ca;
    width: 120px;
    min-width: 100%;
`;

    return (
        <Row className='my-form'>

            <Form onSubmit={submit}>
                <Row className='mx-2 mb-5'>
                    <Col xs={12} className="mb-4">
                        <TextField
                            fullWidth
                            value={name}
                            onChange={onNameChange}
                            label={t("form-name")}
                            name="name"
                            sx={style}
                            required
                        />
                    </Col>
                    <Col xs={6} className="mb-4">
                        <InputMask
                            mask="+\9\9\8 \(99) 999-99-99"
                            maskChar={null}
                            name="number"
                            value={phone}
                            onChange={onPhoneChange}
                            fullWidth
                            required
                        >
                            {() => (
                                <TextField
                                    sx={style}
                                    label={t("form-number")}
                                />
                            )}
                        </InputMask>

                    </Col>
                    <Col xs={6} className="mb-4">
                        <TextField
                            value={email}
                            onChange={onEmailChange}
                            label="Email"
                            name="email"
                            sx={style}
                            fullWidth
                            type='email'
                            required
                        />
                    </Col>
                    <Col xs={12} className="mb-4">
                        <TextField
                            fullWidth
                            value={message}
                            onChange={onMessageChange}
                            sx={style}
                            label={t("form-sms")}
                            name="description"
                            rows={3}
                        />
                    </Col>
                    <div>
                        {pend ? <BeatLoader
                            className='mt-2'
                            css={btnn}
                            color={'#0db8ca'}
                            loading={true}
                            size={20}
                        /> :
                            <button
                                type="submit"
                                className='button mt-2'
                            >
                                {t("send")}
                            </button>
                        }
                    </div>
                </Row>
            </Form>
        </Row >

    )
}

export default MyForm