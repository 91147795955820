import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Autoplay, Pagination, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PartnersSection from '../Sections/PartnersSection';
import { HashLink } from 'react-router-hash-link';

import about1 from '../../resources/img/About/about7901.webp'
import about2 from '../../resources/img/About/about7995.webp'
import about3 from '../../resources/img/About/IMG_7890.jpg'
import about22 from '../../resources/img/About/IMG_7912.jpg'
import about33 from '../../resources/img/About/IMG_7947.jpg'
import CheckIcon from '@mui/icons-material/Check';

import "swiper/css";
import "swiper/css/navigation";
import './About.scss'

export default function AboutPage() {
    const [t] = useTranslation()

    return (
        <section className='about'>

            <div className='about__header'>
                <div className='about__overlay' >
                    <Container className='text-center'>
                        <h1>
                            {t("О НАС")}
                        </h1>
                        <span>ORANGE TEXTILE</span>
                    </Container>
                </div>
            </div>

            <Row className='about__us g-0 overflow-hidden'>
                <Col md={6} data-aos="zoom-in-right">
                    <img src={about3} alt="about" />
                </Col>

                <Col md={6} data-aos="zoom-in-left" className="about__us-text">
                    <h3>
                        {t("О НАС")}
                    </h3>

                    <h4>
                        {t("about-title")}
                    </h4>

                    <p>
                        <div dangerouslySetInnerHTML={{ __html: t('about-text') }} />
                    </p>

                    <span>
                        {t("about-dot")}
                    </span>

                    <ul>
                        <li><CheckIcon /> {t("about-1")}</li>
                        <li><CheckIcon /> {t("about-2")}</li>
                    </ul>

                    <p>
                        {t("about-text2")}
                    </p>

                </Col>
            </Row>

            <Container className='overflow-hidden'>
                <h3 className='about__service fs-1'>
                    {t("our-services")}
                </h3>

                <Row className='about__pics'>
                    <Col sm={12}><div id="dyeing" className='about__line' /></Col>

                    <Col data-aos="fade-right" md={6}>
                        <h4>
                            {t("our-services-title1")}
                        </h4>

                        <p>
                            <div dangerouslySetInnerHTML={{ __html: t('our-services-text1') }} />
                        </p>

                        <HashLink className="button ms-5" to="/about#order">{t("customers")}</HashLink>
                    </Col>

                    <Col data-aos="fade-left" md={6} className="about__img">
                        <img src={about1} alt="about" className='about__img-big' />
                        <img src={about22} alt="about" className='about__img-small2' />
                    </Col>

                    <Col sm={12}><div id="packing" className='about__line' /></Col>

                    <Col data-aos="fade-right" md={6} className="mb-lg-3 mb-0 about__img">
                        <img src={about2} alt="about" className='about__img-big' />
                        <img src={about33} alt="about" className='about__img-small' />

                    </Col>

                    <Col data-aos="fade-left" md={6}>

                        <h4>
                            {t("our-services-title2")}
                        </h4>

                        <p>
                            {t("our-services-text2")}
                        </p>

                        <HashLink className="button ms-5" to="/about#order">{t("customers")}</HashLink>
                    </Col>
                </Row>
            </Container>

            <div className='swiper-bg' id="order">
                <Row className='g-0'>
                    <Col lg={5} className="mb-4 mb-lg-0">
                        <h3>
                            {t("for-customers")}
                        </h3>
                    </Col>

                    <Col lg={7} >
                        <Swiper
                            slidesPerView='auto'
                            spaceBetween={30}
                            freeMode={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                                stopOnLastSlide: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, FreeMode, Pagination]}
                            className="swiper__img pb-4"
                        >
                            <Row className='overflow-hidden'>
                                <SwiperSlide>
                                    <div>
                                        <p>
                                            {t("for-customers")}
                                        </p>
                                        <span dangerouslySetInnerHTML={{ __html: t('design1-about') }} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <p>
                                            {t("design2")}
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <p>
                                            {t("design3")}
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <p>
                                            {t("design4")}
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <p>
                                            {t("design5")}
                                        </p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div>
                                        <p>
                                            {t("design6")}
                                        </p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div>
                                        <p>
                                            {t("design7")}
                                        </p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div>
                                        <p>
                                            {t("design8")}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            </Row>
                        </Swiper>
                    </Col>
                </Row>
            </div>


            <Container>
                <h3 className='about__service fs-1'>
                    {t("quality")}
                </h3>
                <p className='text-center mt-3 mb-5'>
                    {t("quality-text")}
                </p>
            </Container>


            <PartnersSection />
        </section>
    )
}
