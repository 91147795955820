import { useTranslation } from "react-i18next";

function useMenu() {
  const { t } = useTranslation();

  const getLi = [
    {
      link: "/",
      title: t("Главная"),
    },
    {
      link: "/about",
      title: t("О нас"),
    },
    {
      link: "/products",
      title: t("Продукция"),
    },
    {
      link: "/news",
      title: t("Новости"),
    },
    {
      link: "/contacts",
      title: t("Контакты"),
    },
  ];

  const getMobLi = [
    {
      link: "/",
      title: t("Главная"),
    },
    {
      link: "/about",
      title: t("О нас"),
    },
    {
      link: "/products",
      title: t("Продукция"),
    },
    {
      link: "/",
      title: t("Новости"),
    },
    {
      link: "/contacts",
      title: t("Контакты"),
    },
  ];

  return { getLi, getMobLi };
}

export default useMenu;
