import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import useMenu from '../../hooks/useMenu';
import { getAvialableLangs } from '../../hooks/useLang';
import LanguageContext from '../../context/LanguageContext';
import { debounce } from './debounce'
import logo from '../../resources/img/logo.png'

import { Container, NavDropdown } from 'react-bootstrap';

import './Header.scss';

const Header = () => {
    const [language, setLanguage] = useContext(LanguageContext)
    const { t, i18n } = useTranslation()
    const [open, setOpen] = useState(false)
    const menuLi = useMenu()

    const changeLang = useCallback(lang => {
        setLanguage(lang)
        i18n.changeLanguage(lang)
    }, [])

    // Dropdown

    const handleClick = useCallback(() => {
        setOpen(open => !open)

        document.body.classList.toggle("active")
    }, [])

    return (
        <View
            onChangeLang={changeLang}
            onOpen={handleClick}
            data={{ language, menuLi, open, t }} />
    )
}

const View = props => {
    const data = useMemo(() => (props.data), [props.data])

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true)
    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;

        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 100) || currentScrollPos < 100)
        setPrevScrollPos(currentScrollPos);
    }, 40);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.body.click()
        }
    }, [prevScrollPos, visible, handleScroll]);

    return (
        <header className="header" style={{ top: visible ? '0' : '-80px' }} >

            <Container>
                <div className="menu">
                    <Link to="/" className='d-flex align-items-center '>
                        <img src={logo} className='menu__brand' />
                    </Link>
                    {/* Hamburger button */}

                    <div className={data.open ? "menu__hamburger active" : "menu__hamburger"} onClick={props.onOpen}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    {/* Logo */}



                    {/* Menu */}

                    <ul className="menu__list">
                        {
                            data.menuLi.getLi.map((item, index) => (
                                <li className="menu__item" key={index}>
                                    {
                                        !item.children
                                            ? (
                                                <NavLink to={item.link} className={({ isActive }) => isActive ? "active" : null}>
                                                    {item.title}
                                                </NavLink>
                                            ) : (
                                                <NavLink to={item.link} className={({ isActive }) => isActive ? "active p-0" : "p-0"}>
                                                    <NavDropdown
                                                        id="nav-dropdown-dark-example"
                                                        title={
                                                            <>{item.title}</>
                                                        }
                                                        renderMenuOnMount={true}
                                                        className="item"
                                                    >
                                                        {
                                                            item.children.map((item, i) => (
                                                                <NavLink
                                                                    to={item.link}
                                                                    className={({ isActive }) => isActive ? "dropdown-item active" : "dropdown-item"}
                                                                    key={i}
                                                                >
                                                                    {item.title}
                                                                </NavLink>
                                                            ))
                                                        }
                                                    </NavDropdown>
                                                </NavLink>
                                            )
                                    }
                                </li>
                            ))
                        }
                    </ul>

                    <div className="menu__lang">

                        {/* Language Dropdown */}

                        <NavDropdown
                            id="nav-dropdown-dark-example"
                            title={
                                <>
                                    {data.language}
                                </>
                            }
                            onSelect={(e) => {
                                props.onChangeLang(e)
                            }}
                            renderMenuOnMount={true}
                        >
                            {
                                getAvialableLangs().map((lang, index) => {
                                    return (
                                        data.language !== lang
                                            ? <NavDropdown.Item key={index} eventKey={lang}>{lang}</NavDropdown.Item>
                                            : null
                                    )
                                })
                            }
                        </NavDropdown>
                    </div>

                    {/* Mobile Menu */}

                    <div className={data.open ? "mob-menu active" : "mob-menu"}>
                        <div className="mob-menu__content">
                            <div className="mob-menu__header">
                                {/* Social */}

                                <div className="mob-menu__social">
                                    <Link to="/">
                                        <span>ORANGE TEXTILE</span>
                                    </Link>
                                </div>
                            </div>

                            <div className="mob-menu__body">
                                <ul className="mob-menu__list">
                                    {
                                        data.menuLi.getMobLi.map((item, index) => (
                                            <li className="mob-menu__item" key={index}>
                                                <NavLink
                                                    to={item.link}
                                                    onClick={props.onOpen}
                                                    className={({ isActive }) => isActive ? "active" : null}> {item.title}
                                                </NavLink>
                                            </li>
                                        ))
                                    }
                                </ul>

                                <ul className="mob-menu__lang">
                                    {
                                        getAvialableLangs().map((lang, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    onClick={e => props.onChangeLang(e.currentTarget.textContent)}
                                                    className={data.language === lang ? "active" : null}>{lang}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="overlay" onClick={props.onOpen}></div>
                    </div>
                </div>
            </Container>

        </header>
    )
}

export default Header;